<template>
    <div class="o-layout o-layout--medium">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <recess-button :title="BUTTON_TEXT.goBack" icon="back" variant="variant4" class="qa-go-back-button" @click="$router.go(-1)" />

                    <title-text :title="PROVIDER.Order.OrderDetails.Title" class="qa-order-title" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <!-- Overlay block -->
                    <recess-card v-if="order" variant="variant1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="qa-order-summary-number">
                                        {{ PROVIDER.Order.OrderDetails.OrderNumber }}
                                        {{ order.orderNumber }}
                                    </h2>
                                    <p>
                                        <span class="qa-order-student-full-name">
                                            {{ getStudentFullName }}
                                        </span>
                                        -
                                        <span class="qa-order-summary-name">{{ order.name }}</span>
                                    </p>
                                </div>

                                <div class="col-12">
                                    <div class="d-flex flex-wrap justify-content-between">
                                        <p class="d-flex qa-order-status">
                                            <span class="u-text-bold mr-2">{{ PROVIDER.Order.OrderDetails.Status }}</span>
                                            {{ showOrderStatusDisplayValue(order.status) }}
                                        </p>
                                        <div>
                                            <recess-button
                                                v-if="showProviderApprovalButtons(order.status)"
                                                variant="tertiary"
                                                :title="BUTTON_TEXT.acceptOrder"
                                                :disabled="disableProviderApprovalButtonsForAdmin"
                                                class="mr-3 qa-order-details-accept-button"
                                                @click.native.prevent="acceptOrderHandler()"
                                            />
                                            <recess-button
                                                v-if="showProviderApprovalButtons(order.status)"
                                                variant="tertiary"
                                                :title="BUTTON_TEXT.rejectOrder"
                                                :disabled="disableProviderApprovalButtonsForAdmin"
                                                class="qa-order-details-reject-button"
                                                @click.native.prevent="showRejectOrderModal(order.id)"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <!-- Algemeen -->
                                <order-general-info-component 
                                    class="col-12" 
                                    :course-start-moments-options="courseStartmomentsOptions" 
                                    :allow-editing-order-status="allowEditingOrderStatus"
                                />

                                <!-- Prijs details -->

                                <price-details-table-component 
                                    :price-info="setPriceInfo" 
                                    class="col-12" />

                                <!-- Student details -->
                                <edit-student-details-form 
                                    v-if="order.customer" 
                                    class="col-12" 
                                    :allow-editing-order-status="allowEditingOrderStatus"
                                />

                                <!-- Facturatie -->
                                <!-- if user is admin, show real billTo address -->
                                <user-info-table-component v-if="shownUserInfoTable" :user="order.billTo" :invoiced-by="order.invoicedByDisplayValue" class="col-12">
                                    <template slot="edit-invoice-on-behalf-of">
                                        <invoice-on-behalf-of-component />
                                    </template>
                                </user-info-table-component>

                                <!-- if user is provider, show default billTo address -->

                                <provider-billing-table-component class="col-12" v-if="showProviderBillingTable" :order="order">
                                    <template slot="edit-invoice-on-behalf-of">
                                        <invoice-on-behalf-of-component />
                                    </template>
                                </provider-billing-table-component>

                                <div class="col-12" v-if="isDoNotInvoice">
                                    <recess-divider variant="large" show-line />
                                    <div>
                                        <h2 class="qa-do-not-invoice-table">
                                            {{ PROVIDER.Order.OrderDetails.InvoiceDetailsTitleForAdmin }}
                                        </h2>
                                    </div>
                                    <!-- if the order is not to be invoiced, we don't show any billing details -->
                                    <div>
                                        <p v-if="this.order.invoicedBy" class="qa-billing-order-invoiced-by">
                                            {{ this.order.invoicedByDisplayValue || '-' }}
                                        </p>

                                        <slot name="edit-invoice-on-behalf-of"></slot>

                                        <template slot="edit-invoice-on-behalf-of">
                                            <invoice-on-behalf-of-component />
                                        </template>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <h5 class="qa-installments-interval-label">{{ PROVIDER.Order.OrderDetails.Installments.Interval }}</h5>
                                    <p class="qa-installments-interval">{{ this.order.installmentsIntervalDisplayValue }}</p>
                                </div>
                                <div class="col-4">
                                    <h5 class="qa-installments-quantity-label">{{ PROVIDER.Order.OrderDetails.Installments.Quantity }}</h5>
                                    <p class="qa-installments-quantity">{{ this.order.installmentsQuantity }}</p>
                                </div>
                            
                            </div>
                        </div>
                    </recess-card>
                </div>
            </div>
        </div>

        <accept-order-popup-component
            :is-modal-visible="isAcceptOrderModalVisible"
            :startmoment-selection="selectedStartmomentData" 
            :provider-id="providerId" 
            @submit="acceptOrder(order.id)"
            @close="closeAcceptOrderModal"
            :orderNumber="orderNumber"
        />

        <reject-order-popup-component 
            v-if="isRejectOrderModalVisible" 
            :is-modal-visible="isRejectOrderModalVisible" 
            @close="closeRejectOrderModal"
            @submit="rejectOrder(order.id)"
        />
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import acceptRejectOrderMixin from '@/mixins/acceptRejectOrderMixin'
import { getEnumList, getItemById } from '@/../../shared/api/SharedClient'
import UserService from '../../../../shared/services/UserService'
import { formatUtcDateTimeToLocalDate } from '@/utils/dateTimeHelper'

const TitleText = () => import('@/components/atoms/TitleText')
const OrderGeneralInfoComponent = () => import('@/components/organisms/Order/OrderGeneralInfoComponent')
const UserInfoTableComponent = () => import('@/components/organisms/Order/UserInfoTableComponent')
const PriceDetailsTableComponent = () => import('@/components/organisms/Order/PriceDetailsTableComponent')
const ProviderBillingTableComponent = () => import('@/components/organisms/Order/ProviderBillingTableComponent')
const InvoiceOnBehalfOfComponent = () => import('@/components/organisms/Order/InvoiceOnBehalfOfComponent')
const EditStudentDetailsForm = () => import('@/components/organisms/Order/EditStudentDetailsForm')

export default {
    name: 'OrderDetails',
    components: {
        TitleText,
        OrderGeneralInfoComponent,
        UserInfoTableComponent,
        PriceDetailsTableComponent,
        ProviderBillingTableComponent,
        InvoiceOnBehalfOfComponent,
        EditStudentDetailsForm
    },
    mixins: [acceptRejectOrderMixin],
    data() {
        return {
            userService: new UserService(),
            isAdministrator: false,
            BUTTON_TEXT,
            PROVIDER,
            orderId: this.$route.params.id || null,
            providerId: localStorage.getItem('providerId'),
            orderStatus: [],
            isManualOrApiCourse: true,
            selectedStartmomentData: null,
            orderNumber: '',
            courseStartmomentsOptions: []
        }
    },
    async created() {
        this.isAdministrator = await this.userService.isAdministrator()
    },
    computed: {
        ...mapState('orderModule', ['order']),
        getStudentFullName() {
            if (this.order && this.order.customer) {
                return `${this.order.customer.firstName} ${this.order.customer.surname}`
            }
            return ''
        },
        priceDetails() {
            if (!this.order || !this.order.orderLines || this.order.orderLines.length < 1) return null
            return this.order.orderLines
        },
        isOpleidingDirect() {
            return this.order.invoicedBy === PROVIDER.Order.OrderDetails.InvoicedBy.OpleidingDirect
        },
        isThirdParty() {
            return this.order.invoicedBy === PROVIDER.Order.OrderDetails.InvoicedBy.ThirdParty
        },
        isDoNotInvoice() {
            return this.order.invoicedBy === PROVIDER.Order.OrderDetails.InvoicedBy.DoNotInvoice
        },
        isOPLZ() {
            return this.order.invoicedBy === PROVIDER.Order.OrderDetails.InvoicedBy.OPLZ
        },
        isNCOI() {
            return this.order.invoicedBy === PROVIDER.Order.OrderDetails.InvoicedBy.NCOI
        },
        shownUserInfoTable() {
            if (this.isDoNotInvoice) {
                return false
            }
            return (this.isAdministrator || this.isThirdParty) && this.order.billTo
        },
        showProviderBillingTable() {
            if (this.isDoNotInvoice) {
                return false
            }
            return !this.isAdministrator && (!this.order.billTo || this.isOPLZ || this.isNCOI || this.isOpleidingDirect)
        },
        setPriceInfo() {
            const localPriceInfo = {
                details: this.priceDetails,
                total: this.order?.total,
                isVatExempt: this.order?.isVatExempt
            }
            
            return localPriceInfo

        },
        courseId() { 
            return this.order && this.order.productReference && this.order.productReference.id
        },
        allowEditingOrderStatus() {
            const allowedStatus = ['PendingAccount', 'PendingProvider', 'AcceptedProvider']
            return allowedStatus.some((status) => status === this.order.status) && !this.isELearningCourse
        }
    },
    watch: {
		courseId: {
			handler() { 
                if(this.courseId) {
                    this.getCourse()
                }
			}
		}
	},
    mounted() {
        this.$nextTick(() => {
            this.getDataOnPageChange()
            this.getOrderStatus()
            this.$bus.on('update-order-status-transition', this.setOrderStatusTransition)
            this.$bus.on('validate-post-legal-company', this.validatePostLegalCompany)
            this.$bus.on('validate-patch-order-lines', this.validatePatchOrderLines)
            this.$bus.on('refresh-orders', this.getOrdersAfterRebuildOrderIndex)
        })
    },
    beforeDestroy() {
        this.resetOrderState()
        this.$bus.off('update-order-status-transition')
        this.$bus.off('validate-post-legal-company')
        this.$bus.off('validate-patch-order-lines')
        this.$bus.off('refresh-orders')
    },
    methods: {
        ...mapActions('orderModule', ['getOrder']),
        ...mapMutations('orderModule', ['resetOrderState']), 

        async getCourse() {
            try {
                const response = await getItemById(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.courses,
                    this.courseId,
                    null,
                    false
                )
                if (!response) return

				const courseStartmoments = response.learningMethod.planning.startMoments.reduce((acc, startMoment) => {
                    if (!startMoment.inactive) { 
						const fullDate = {
							displayText: `${formatUtcDateTimeToLocalDate(startMoment.startDate.fullDate)} - ${startMoment.city}`,
							value: startMoment.externalId
						}
 
						return [...acc, fullDate]
                    }
                    return acc
                }, [])  

				this.courseStartmomentsOptions = courseStartmoments
               
            } catch (error) {
                console.error('Something went wrong while retrieving course details', error)
            }
        },

        getDataOnPageChange() {
            this.getOrder(this.orderId)
        },
        showOrderStatusDisplayValue(status) {
            return (
                this.orderStatus &&
                this.orderStatus.reduce((acc, currentValue) => {
                    if (currentValue.value === status) {
                        return currentValue.displayText
                    }
                    return acc
                }, [])
            )
        },
        async getOrderStatus() {
            try {
                const response = await getEnumList(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.enumerations, 'orderStatus', null, null, false)
                if (!response) return
                this.orderStatus = [...this.orderStatus, ...response]
            } catch (error) {
                console.error('Something went wrong while retrieving order status enum list', error)
            }
        },
        formatDateAndMonthToTwoDigits(value) {
            if (value > 0 && value < 10) {
                return String(value).padStart(2, '0')
            }
            return value
        },
        acceptOrderHandler() {
            const firstStartmomentDate = `${this.order.startMomentYear}-${this.formatDateAndMonthToTwoDigits(this.order.startMomentMonth)}-${
                this.order.startMomentDay ? this.formatDateAndMonthToTwoDigits(this.order.startMomentDay) : '01'
            }`
            const secondStartmomentDate = `${this.order.secondStartMomentYear}-${this.formatDateAndMonthToTwoDigits(this.order.secondStartMomentMonth)}-${
                this.order.secondStartMomentDay ? this.formatDateAndMonthToTwoDigits(this.order.secondStartMomentDay) : '01'
            }`

            this.selectedStartmomentData = {
                orderFirstStartmomentDate: firstStartmomentDate,
                orderFirstStartmomentId: this.order.customAttributes.firstChoiceStartMomentId,
                orderFirstChoiceStartMomentLocation: this.order.startMomentLocation,
                orderSecondStartmomentDate: secondStartmomentDate,
                orderSecondStartmomentId: this.order.customAttributes.secondChoiceStartMomentId,
                orderSecondChoiceStartMomentLocation: this.order.secondStartMomentLocation,
                courseStartmomentsOptions: this.courseStartmomentsOptions
            }

            this.orderNumber = `${this.order.orderNumber}`

            this.showAcceptOrderModal()
        }
    }
}
</script>
